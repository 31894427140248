import Whisper from '../components/whisper'

// Bootstrap
import { Tooltip, Toast, Popover } from 'bootstrap'

window.mountedComponents = []
window.mountedWhispers = []
window.whispers = () => {
  const whispers = document.querySelectorAll('input[data-whisper]')
  whispers.forEach( element => {
    if (!element.dataset.whisperMounted) {
      element.setAttribute('autocomplete','off')
      element.dataset.whisperMounted = true
      element.parentNode.classList.add('whisper-wrapper')
      let div = document.createElement('div')
      div.classList.add('whisper-container')
      element.parentNode.appendChild(div)
      let props = {input: element}
      if (element.dataset.props) {
        Object.assign(JSON.parse(element.dataset.props), {
          input: element
        })
      }
      let component = ReactDOM.render(
        React.createElement(Whisper, props),
        div
      )
      window.mountedComponents.push(component)
      window.mountedWhispers.push(component)
    }
  })
}

// $('input[data-whisper]').each ->
//   input = $(this)
//   input.prop('autocomplete','off')
//   input_dom = $(this)[0]
//   wrapper = input.closest('div')
//   wrapper.addClass('whisper-wrapper')
//   if wrapper.find('.whisper-container').length == 0
//     wrapper.append('<div class="whisper-container" />')
//     container_dom = wrapper.find('.whisper-container')[0]
//     react_whisper = ReactDOM.render(React.createElement(window.WhisperPublic, {
//       url: input_dom.dataset.url
//       scenario: "cart",
//       input: $(this),
//       }), container_dom)
//     unless $.react_whispers
//       $.react_whispers = []
//     $.react_whispers.push(react_whisper)
//     return true

const unmountComponents = () => {
  window.mountedComponents.forEach(component => {
    try {
      ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(component).parentNode)
    }
    catch(error) {
      // console.log(error)
    }
  })
}

const domReady = function() {
  window.whispers()
}

document.addEventListener('DOMContentLoaded', domReady)

$(window).bind('beforeunload', () => {
  unmountComponents()
})

// Disable scroll on input type number
// jQuery(document).on("wheel", "input[type=number]", function (e) {
//   jQuery(this).blur()
// })
