class Whisper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      results: [],
      index: -1,
      loading: false,
      fastSelect: false
    };

    this.currentRequest = null
  }

  componentDidMount() {
    let _this = this
    if (!this.props.input.dataset.keyUpBinded) {
      this.props.input.dataset.keyUpBinded = true
      this.props.input.addEventListener('focusout', (e) => {
        setTimeout(() => {
          _this.setState({query: "", index: -1})
        }, 200)
      })
      this.props.input.addEventListener('keyup', (e) => {
        switch (e.keyCode) {
          case 27:
            _this.setState({query: "", index: -1})
            break
          case 40:
            // Arrow Down
            if (_this.state.results.length-1 > _this.state.index) _this.setState({index: _this.state.index + 1})
            if (_this.state.query == "") _this.setState({query: _this.props.input.value})
            break
          case 38:
            // Arrow Up
            if (_this.state.index > 0) _this.setState({index: _this.state.index - 1})
            break
          case 13:
            // Enter
            if (_this.state.results[_this.state.index]) {
              _this.handleSelect(_this.state.results[_this.state.index])
              _this.setState({query: "", index: -1})
              break
            }

            // Fast SN select
            if (_this.fastSelect()) {
              break
            }

          default:
            _this.setState({query: _this.props.input.value})
            _this.fetchResults()
        }
      })
    }

    if (!this.props.input.dataset.keyDownBinded) {
      this.props.input.dataset.keyDownBinded = true
      this.props.input.addEventListener('keydown', (e) => {
        if (e.keyCode == 13) e.preventDefault()
      })
    }
  }

  fastSelect(inSwitch = false) {
    let _this = this
    this.setState({fastSelect: true})

    if (
      _this.props.scenario == 'react_inventory_records_products' &&
      _this.state.results.length == 1
    ) {
      let result = _this.state.results[0]
      let query = _this.state.query.toLowerCase().trim()
      let inventory_record = result.inventory_records.find(i => i.serial_number.toLowerCase().trim() == query)
      if (inventory_record) {
        if (!window.react_inventory_records.state.products.find(i => i.id == result.id)) {
          window.react_inventory_records.state.products.unshift(result)
        }

        if (!window.react_inventory_records.state.records.find(i => i.id == inventory_record.id)) {
          window.react_inventory_records.state.records.unshift(inventory_record)
        }

        window.react_inventory_records.setState({
          products: window.react_inventory_records.state.products,
          records: window.react_inventory_records.state.records,
          focus: true
        })
        this.setState({query: "", index: -1})
        this.props.input.value = ''

        this.setState({fastSelect: false})
        return true
      }
    }
  }

  handleSelect(result, e) {
    if (e) e.preventDefault()

    let row;
    switch (this.props.scenario) {
      case 'invoice_item':
        this.setState({query: "", index: -1})
        row = jQuery(this.props.input).closest('.nested-fields')
        row.find('input.code').val(result.code)
        row.find('input.ean').val(result.ean)
        row.find('input.name').val(result.name)
        row.find('input.product_id').val(result.id)
        row.find('input.value').val(result.price).trigger('change')
        break
      case 'in_inventory':
        this.setState({query: "", index: -1})
        row = jQuery(this.props.input).closest('.nested-fields')
        row.find('input.code').val(result.code)
        row.find('input.ean').val(result.ean)
        row.find('input.name').val(result.name)
        row.find('input.product_id').val(result.id)
        if (result.purchase_price) row.find('input.price').val(result.purchase_price)
        break
      case 'out_inventory':
        this.setState({query: "", index: -1})
        row = jQuery(this.props.input).closest('.nested-fields')
        row.find('input.code').val(result.code)
        row.find('input.ean').val(result.ean)
        row.find('input.name').val(result.name)
        row.find('input.id').val(result.id)
        break
      case 'out_inventory_precise':
        this.setState({query: "", index: -1})
        row = jQuery(this.props.input).closest('.nested-fields')
        row.find('input.code').val(result.code).prop('readonly', true)
        row.find('input.ean').val(result.ean).prop('readonly', true)
        row.find('input.name').val(result.name).prop('readonly', true)
        row.find('input.amount').val(1).prop('readonly', true)
        row.find('input.serial_number').val(result.serial_number).prop('readonly', true)
        row.find('input.id').val(result.id)
        break
      case 'react_inventory_records':
        window.react_inventory_records.state.records.unshift(result)
        window.react_inventory_records.setState({records: window.react_inventory_records.state.records})
        if (result.type == "InventoryRecord") {
          let products_helper = window.react_inventory_records.state.products.map(i => {
            if (i.id == result.product_id) {
              if (i.amount > 1) {
                i.amount -= 1
                return i
              } else {
                return null
              }
            } else {
              return i
            }
          })
          products_helper = products_helper.filter(i => i != null)
          window.react_inventory_records.setState({products: products_helper, focus: true})
        }
        this.setState({query: "", index: -1})
        this.props.input.value = ''
        break
      case 'react_inventory_records_products':
        window.react_inventory_records.state.products.unshift(result)
        window.react_inventory_records.setState({products: window.react_inventory_records.state.products, focus: true})
        this.setState({query: "", index: -1})
        this.props.input.value = ''
        break
    }
  }

  fetchResults() {
    let _this = this
    if (this.state.query.length > 2) {
      if (_this.currentRequestTimeout) clearTimeout(_this.currentRequestTimeout)
      _this.currentRequestTimeout = setTimeout(() => {
        const currentRequest = jQuery.ajax({
          method: 'GET',
          url: this.props.url,
          data: {q: this.state.query},
          beforeSend: () => {
            if (_this.currentRequest) {
              _this.currentRequest.abort()
            }
            _this.currentRequest = null
            _this.setState({loading: true})
          },
          success: function(data) {
            let fastSelect = _this.state.fastSelect
            _this.setState({results: data.results, fastSelect: false}, () => {
              if (fastSelect) {
                _this.fastSelect()
              }
            })

            if (data.results.length && _this.state.query) {
              let results = []

              if (window.react_inventory_records) {
                switch (_this.props.scenario) {
                  case 'react_inventory_records':
                    results = data.results.filter(i => !window.react_inventory_records.state.records.find(ii => ii.id == i.id))
                    break
                  case 'react_inventory_records_products':
                    results = data.results.filter(i => !window.react_inventory_records.state.products.find(ii => ii.id == i.id))
                    break
                }

                let result = results.find( i => i.ean == _this.state.query )
                if (result) _this.handleSelect(result, null)
              }
            }
          },
          complete: () => {
            _this.setState({loading: false})
            _this.currentRequest = null
          }
        })

        _this.currentRequest = currentRequest
      }, 800)
    }
  }

  render() {
    let results = this.state.results

    if (window.react_inventory_records) {
      switch (this.props.scenario) {
        case 'react_inventory_records':
          results = results.filter(i => !window.react_inventory_records.state.records.find(ii => ii.id == i.id))
          break
        case 'react_inventory_records_products':
        results = results.filter(i => !window.react_inventory_records.state.products.find(ii => ii.id == i.id))
          break
      }
    }

    return (
      <div className={classNames("whisper", {"hidden": !(this.state.query.length > 2)})}>
        {this.state.loading ? (
          <div className="loading">
            <i className="fas fa-circle-notch fa-spin"></i>
          </div>
        ) : null}
        <table className="results-table">
          <thead>
            <tr>
              <th>Obr.</th>
              <th>Kód</th>
              <th>EAN</th>
              <th>Název</th>
              {this.props.url.includes("inventory_records") ? null : (<th>Skladem</th>)}
              {this.props.url.includes("inventory_records") ? (<th>S/N</th>) : null}
            </tr>
          </thead>
          <tbody className="results">
            {results.map((i, index) => {
              return(
                <tr
                  key={i.id}
                  onClick={this.handleSelect.bind(this, i)}
                  className={classNames("result", {"focused": index == this.state.index})}>

                  <td className="image">
                    {i.thumb_url ? (
                      <img src={i.thumb_url} alt={i.name}/>
                    ) : null}
                  </td>
                  <td>{i.code}</td>
                  <td>{i.ean}</td>
                  <td>{i.name}</td>
                  {this.props.url.includes("inventory_records") ? null : (<td>{i.inventory}</td>)}
                  {this.props.url.includes("inventory_records") ? (<td>{i.serial_number}</td>) : null}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Whisper
